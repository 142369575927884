.itm-icon:hover>.itm-showOnHover{
   display: block;
}

.itm-icon-selector-container{
   width: 500px;
   top: 4rem;
   left: -225px;
}
.itm-icon-selector-container:before{
   content: '';
   border-style: solid;
   border-width: 7px;
   border-color: transparent transparent rgb(229, 231, 235);
   position: absolute;
   top: -14px;
   width: 0;
   height: 0;
   left: calc(50% - 10px);
}
.itm-icon-selector-container:after{
   content: '';
   border-style: solid;
   border-width: 6px;
   border-color: transparent transparent #fff;
   position: absolute;
   top: -11px;
   width: 0;
   height: 0;
   left: calc(50% - 9px);
}
.itm-icon-selector-button {
   min-width: 3rem;
   min-height: 3rem;
}