.itm-btn-group .itm-btn:not(:last-child){
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
}
.itm-btn-group .itm-btn:not(:first-child){
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
}
.itm-btn-group .itm-btn:not(:first-child){
   margin-left: -1px;
}