.itm-banner{
   min-height: 480px;
   position: relative;
}

.w-50 {
   width: 50%;
}

.widgetStyle {
   padding: 15px;
   text-align: left;
   margin-left: 0;
   margin-right: auto;
}

.widgetStyle-right {
   padding: 15px;
   text-align: right;
   margin-right: 0;
   margin-left: auto;
}

.widgetStyle-center {
   padding: 15px;
   text-align: center;
   margin-right: auto;
   margin-left: auto;
}