.embed-responsive{
   position: relative;
   display: block;
   width: 100%;
   padding: 0;
   overflow: hidden;
}
.embed-responsive::after{
   display: block;
   clear: both;
   content: "";
}
.embed-responsive::before{
   display: block;
   content: "";
}
.embed-responsive-4by3::before {
   padding-top: 75%;
}
.embed-responsive-item {
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 100%;
   border: 0;
}